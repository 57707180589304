import { z } from 'zod'

const SimplisafeTimeZoneName = z.enum([
  'America/New_York',
  'America/Chicago',
  'America/Denver',
  'America/Los_Angeles',
  'America/Puerto_Rico',
  'America/Phoenix',
  'Pacific/Honolulu',
  'America/Anchorage',
  'Europe/London',
  'Pacific/Guam',
  'Unknown',
  'Unresolved'
])
export type SimplisafeTimeZoneName = z.infer<typeof SimplisafeTimeZoneName>
