import { TableRow, TableCell, Typography } from '@mui/material'
import { FC } from 'react'
import { Message } from '../../api'

interface AlarmSmsTableEntryProps {
  contactFirstName: string
  message: Message
}

function maskPhoneNumber(phoneNumber: string = ''): string {
  // +16179810791 -> ***-***-0791
  return `***-***-${phoneNumber.slice(-4)}`
}

export const AlarmSmsTableEntry: FC<AlarmSmsTableEntryProps> = ({ contactFirstName, message }) => {
  const { timestamp, direction, phoneNumber, message: body } = message
  const date = new Date(timestamp)
  const humanTime = date.toLocaleTimeString()

  return (
    <TableRow sx={{ verticalAlign: 'top' }}>
      <TableCell align="left">{humanTime}</TableCell>
      <TableCell align="left">
        <Typography>
          {direction === 'sent' ? 'to' : 'from'} {contactFirstName}
        </Typography>
        <Typography variant="body2">({maskPhoneNumber(phoneNumber)})</Typography>
      </TableCell>
      <TableCell align="left">
        <Typography style={{ whiteSpace: 'pre-line' }}>{body}</Typography>
      </TableCell>
    </TableRow>
  )
}
