import { Stack, Typography } from '@mui/material'
import { FC, ReactNode } from 'react'
import { AlarmDetails } from '../../api'

interface AlarmCardProps {
  alarm: AlarmDetails
}

const PanelItem: FC<{ label: ReactNode; value: ReactNode }> = ({ label, value }) => {
  return (
    <Stack>
      <Typography fontSize={'1rem'} fontWeight={500}>
        {label}
      </Typography>
      <Typography fontSize={'1.5rem'} fontWeight={300}>
        {value}
      </Typography>
    </Stack>
  )
}

export const AlarmCard: FC<AlarmCardProps> = ({ alarm }) => {
  const humanTime = new Date(alarm.createdTimestamp).toLocaleString()

  return (
    <Stack direction={'row'} alignItems={'start'} justifyContent={'space-between'} flexGrow={1} gap={'1rem'} useFlexGap marginRight={'1rem'}>
      <PanelItem label={'Alarm'} value={alarm.id} />
      <PanelItem label={'Category'} value={alarm.category} />
      <PanelItem label={'Created At'} value={humanTime} />
    </Stack>
  )
}
