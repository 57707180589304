import { FC, PropsWithChildren } from 'react'
import { Outlet } from 'react-router-dom'
import { ErrorBoundary } from '../../components/ErrorBoundary'
import { Box } from '@mui/material'

export const AppLayout: FC<PropsWithChildren> = () => {
  return (
    <Box sx={{ backgroundColor: 'var(--bg-surface-low)' }}>
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </Box>
  )
}
