import { FC } from 'react'
import { Link } from 'react-router-dom'
import { ErrorScreen } from '../../components/ErrorScreen'
import { Page } from '../../components/Page'

export const NotFoundPage: FC = () => {
  return (
    <Page>
      <ErrorScreen
        title="Page Not Found"
        message={
          <>
            You can return to the <Link to="/">home page</Link>
          </>
        }
      />
    </Page>
  )
}
