import { Jurisdiction } from '../../api'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { PermitRenewalCadence, PermitRenewalCadenceDisplayNames } from '../../constants/PermitRenewalCadence.ts'

export function getRenewalRequirement(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  if (jurisdiction.agencyRules[agencyType]?.permitRenewal?.isRequired) {
    return 'Required'
  } else {
    return 'Not Required'
  }
}

export function getRenewalCadence(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  if (jurisdiction.agencyRules[agencyType]?.permitRenewal?.cadence) {
    return PermitRenewalCadenceDisplayNames[jurisdiction.agencyRules[agencyType]?.permitRenewal?.cadence]
  }
  return PermitRenewalCadenceDisplayNames[PermitRenewalCadence.Unknown]
}

export function getRenewalResponsible(jurisdiction: Jurisdiction): string {
  const isSimpliSafeManaged = jurisdiction.policies.renewal.isSimpliSafeManaged
  if (isSimpliSafeManaged) {
    return 'SimpliSafe Renewal'
  } else {
    return 'Customer Renewal'
  }
}

export function getPermitResponsible(jurisdiction: Jurisdiction): string {
  const isSimpliSafeManaged = jurisdiction.policies.registration.isSimpliSafeManaged
  if (isSimpliSafeManaged) {
    return 'SimpliSafe Registration'
  } else {
    return 'Customer Registration'
  }
}

export function getLastResearchedDate(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  if (!jurisdiction.agencyRules[agencyType]?.lastResearchedDate) {
    return 'Not available'
  }
  const date = jurisdiction.agencyRules[agencyType]?.lastResearchedDate
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'UTC'
  })
}

export function isPrivateResponseRequired(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  const isRequired = jurisdiction.agencyRules[agencyType]?.isPrivateResponseRequired
  if (isRequired) {
    return 'Required'
  } else if (!isRequired) {
    return 'Not required'
  } else {
    return 'Unknown'
  }
}

export function getCallVerification(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  const enhancedCallVerificationRequirements = jurisdiction.agencyRules[agencyType]?.enhancedCallVerificationRequirements
  if (!enhancedCallVerificationRequirements?.raw.code) {
    return 'Not available'
  }
  const callsRequired = enhancedCallVerificationRequirements?.raw.code === 'Y'
  if (callsRequired) {
    return `${enhancedCallVerificationRequirements?.count} calls required`
  } else {
    return 'No minimum required'
  }
}

export function getNumberOfFalseAlarmsBeforeSuspension(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  const falseAlarmRules = jurisdiction.agencyRules[agencyType]?.falseAlarmRules
  if (
    (!falseAlarmRules?.suspendable?.raw || !falseAlarmRules?.suspendable?.raw?.limit) &&
    jurisdiction.agencyRules[agencyType]?.permitAdditionalNotes
  ) {
    return 'See Comment'
  } else if (!falseAlarmRules?.suspendable?.raw || !falseAlarmRules?.suspendable?.raw?.limit) {
    return 'Not available'
  }
  const limit = Number(falseAlarmRules?.suspendable?.raw?.limit)
  if (limit > 0) {
    return limit.toString()
  } else {
    return 'See Comment'
  }
}

export function getNumberOfFalseAlarmsBeforeFine(jurisdiction: Jurisdiction, agencyType: AgencyTypeValues): string {
  const falseAlarmRules = jurisdiction.agencyRules[agencyType]?.falseAlarmRules
  if ((!falseAlarmRules?.fineable?.raw || !falseAlarmRules?.fineable?.raw?.limit) && jurisdiction.agencyRules[agencyType]?.permitAdditionalNotes) {
    return 'See Comment'
  } else if (!falseAlarmRules?.fineable?.raw || !falseAlarmRules?.fineable?.raw?.limit) {
    return 'Not available'
  }
  const limit = Number(falseAlarmRules?.fineable?.raw?.limit)
  if (limit > 0) {
    return limit.toString()
  } else {
    return 'See Comment'
  }
}
