import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocationLayout } from '../LocationLayout'

export const AlarmSmsLayout: FC = () => {
  const navigate = useNavigate()

  const onSidChanged = (sid: string) => {
    navigate(`./alarm-sms-review/${sid ?? ''}`)
  }

  return <LocationLayout title={'Alarm SMS Review'} onSidChanged={onSidChanged} />
}
