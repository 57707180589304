export function getUserInitials(profileName: string): string {
  const splitName = profileName.split(' ')
  let userInitials = ''

  for (let index = 0; index < splitName.length; index++) {
    const value = splitName[index].charAt(0).toUpperCase()
    userInitials = userInitials.concat(value)
  }

  return userInitials.toUpperCase()
}

/**
 * Converts a string value as a boolean. The following values are considered true: true, yes, TRUE, YES, 1. Everything else is considered false.
 * @param inputStr
 */
export function bool(inputStr: string | null | undefined): boolean {
  if (inputStr === undefined || inputStr === null) {
    return false
  }
  const lower = inputStr.toLowerCase().trim()
  return lower === 'true' || lower === 'yes' || lower === '1'
}
