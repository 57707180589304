import { FC } from 'react'
import { AlarmTableEntry } from './AlarmTableEntry'
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'

interface Alarm {
  id: string
  category: string
  createdTimestamp: string
}

export const AlarmTable: FC<{ alarms: Alarm[] }> = ({ alarms }) => {
  return (
    <Paper data-testid="alarms">
      <Typography variant={'sectionHeading'} p={'1rem'} data-testid="alarms-count">
        Alarms ({alarms.length})
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">CREATED AT</TableCell>
              <TableCell>CATEGORY</TableCell>
              <TableCell>IDENTIFIER</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {alarms.map(alarm => (
              <AlarmTableEntry key={alarm.id} alarmId={alarm.id} category={alarm.category} timestamp={alarm.createdTimestamp} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
