import { useAuth0 } from '@auth0/auth0-react'
import { Avatar, Divider, Stack } from '@mui/material'
import { NavLink, useParams } from 'react-router-dom'
import { SIDEBAR_JURISDICTION_DETAILS, SIDEBAR_PERMITS } from '../../constants'
import { getUserInitials } from '../../utils/utils.ts'
import { FC } from 'react'

/**
 * Object that contains the main properties of a sidebar link.
 */
type LinkDefinition = {
  label: string
  targetUrl: string
}

/**
 * Type of the props for the `Sidebar` component.
 */
interface SidebarProps {}

/**
 * A component that displays a sidebar with a profile and list of pages.
 */
export const Sidebar: FC<SidebarProps> = () => {
  const { sid } = useParams()
  const { user } = useAuth0()

  const profileName = user?.name ?? ''
  const userInitials = getUserInitials(profileName)

  const sidebarLinkDefinitions: LinkDefinition[] = [
    {
      label: SIDEBAR_PERMITS,
      targetUrl: sid ? `/permits/${sid}` : '/permits'
    },
    {
      label: SIDEBAR_JURISDICTION_DETAILS,
      targetUrl: sid ? `/jurisdiction-details/${sid}` : '/jurisdiction-details'
    }
  ]

  /**
   * The list of link components to be displayed in the sidebar.
   */
  const sidebarLinks = sidebarLinkDefinitions.map(({ label, targetUrl }, index) => {
    const key = `${label}\n${targetUrl}\n${index}`
    return (
      <NavLink
        key={key}
        to={targetUrl}
        style={({ isActive }) => ({
          fontWeight: '500',
          fontSize: '1rem',
          color: isActive ? 'var(--link-default)' : 'var(--text-secondary)',
          textTransform: 'uppercase',
          textDecorationLine: 'none',
          borderRightWidth: '0.1875rem',
          borderRightStyle: 'solid',
          borderRightColor: isActive ? 'var(--link-default)' : 'transparent',
          paddingBlock: '0.5rem',
          paddingInline: '1.5rem'
        })}
      >
        {label}
      </NavLink>
    )
  })

  return (
    <Stack direction={'column'} alignItems={'stretch'} width={'14rem'} flex={'0 0 auto'} bgcolor={'var(--bg-page)'}>
      <Stack direction={'column'} alignItems={'stretch'} sx={{ paddingInline: '1.5rem' }}>
        <Stack
          sx={{
            gap: '1rem',
            flexDirection: 'row',
            fontSize: '1rem',
            color: 'var(--text-primary)',
            fontWeight: '500',
            minHeight: '7.5rem',
            alignItems: 'center',
            justifyContent: 'start',
            paddingBlock: '1.5rem',
            wordBreak: 'break-word'
          }}
        >
          <Avatar
            sx={{
              backgroundColor: 'var(--bg-illustration)',
              fontSize: '1rem',
              fontWeight: '700',
              color: 'var(--text-primary)'
            }}
          >
            {userInitials}
          </Avatar>
          {profileName}
        </Stack>
        <Divider flexItem color={'#BAB1AA'} sx={{ marginBlockEnd: '1.5rem' }} />
      </Stack>
      <Stack direction={'column'} spacing={'0.5rem'} alignItems={'stretch'}>
        {sidebarLinks}
      </Stack>
    </Stack>
  )
}
