import { DependencyList, useEffect } from 'react'
import { debounce } from 'lodash'

/**
 * This hook designed to work exactly like useEffect, except that it will fire when the window resizes.
 * The resizeFn is called immediately and then debounced on window resizes.
 * @param resizeFn
 * @param deps - deps to use with useEffect
 */
export const useResizeEffect = (resizeFn: () => void, deps?: DependencyList): void => {
  useEffect(() => {
    resizeFn()

    const debouncedResizeFn = debounce(resizeFn, 50)

    window.addEventListener('resize', debouncedResizeFn)

    return () => {
      window.removeEventListener('resize', debouncedResizeFn)
    }
  }, deps)
}
