import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Pagination,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@mui/material'
import React, { useMemo, useState } from 'react'
import { Location, LocationPermittingCommentRequest } from '../../api'
import AddIcon from '@mui/icons-material/Add'
import { useCreatePermittingComment, usePermittingComments } from '../../api/apiQueries.ts'
import { formatIsoTimestampForLocation } from '../../utils/dateUtils.ts'
import { useForm } from 'react-hook-form'
import LoadingButton from '@mui/lab/LoadingButton'
import { useSnackbar } from '../../hooks/useSnackbar.tsx'
import { WRITE_PERMITTING_COMMENTS_SCOPE } from '../../constants'
import { AuthCheck } from '../../components/AuthCheck'
import { useBlocker } from 'react-router-dom'

interface NotesProps {
  location: Location
}

type NoteForm = {
  content: string
}

const emptyForm = { content: '' }

const Notes: React.FC<NotesProps> = ({ location }) => {
  const [page, setPage] = useState(1)
  const [isAddingNote, setIsAddingNote] = useState(false)
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const rowsPerPage = 4
  const { mutateAsync, isPending: isCreatingComment } = useCreatePermittingComment()
  const { toast } = useSnackbar()

  const { data: notesData, isLoading } = usePermittingComments(location.id)

  const flattenedData = useMemo(() => {
    if (!notesData) return []
    return notesData.map(note => ({
      content: note.content,
      updatedAt: formatIsoTimestampForLocation(note.updatedAt, location),
      updatedBy: note.updatedBy
    }))
  }, [notesData, location])

  const totalItems = flattenedData.length
  const totalPages = Math.ceil(totalItems / rowsPerPage)
  const startIndex = (page - 1) * rowsPerPage
  const endIndex = Math.min(startIndex + rowsPerPage, totalItems)

  const handleChangePage = (event: React.ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  const handleAddNote = () => {
    setIsAddingNote(true)
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
    watch
  } = useForm<NoteForm>({ defaultValues: emptyForm })

  const noteContent = watch('content')

  const blocker = useBlocker(({ currentLocation, nextLocation }) => isDirty && currentLocation.pathname !== nextLocation.pathname)

  const handleCancelNote = () => {
    if (isDirty) {
      setShowConfirmDialog(true)
    } else {
      if (showConfirmDialog) {
        handleCancelCancel()
      } else {
        setIsAddingNote(false)
      }
    }
  }

  const handleConfirmCancel = () => {
    reset(emptyForm)
    setIsAddingNote(false)
    setShowConfirmDialog(false)
    if (blocker.state === 'blocked') {
      blocker.proceed()
    }
  }

  const handleCancelCancel = () => {
    setShowConfirmDialog(false)
    if (blocker.state === 'blocked') {
      blocker.reset()
    }
  }

  const onSubmit = async (data: NoteForm) => {
    const request: LocationPermittingCommentRequest = {
      content: data.content
    }
    try {
      await mutateAsync({ locationId: location.id, request })
      toast('Note added.', { variant: 'success' })
      reset(emptyForm)
    } finally {
      setIsAddingNote(false)
    }
  }

  return (
    <Paper data-testid="notes">
      <Stack direction={'row'} p={'1rem'} justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant={'sectionHeading'}>Internal Notes</Typography>
        <AuthCheck has={WRITE_PERMITTING_COMMENTS_SCOPE}>
          <Button variant="text" startIcon={<AddIcon />} onClick={handleAddNote} data-testid={'add-note'}>
            Add Note
          </Button>
        </AuthCheck>
      </Stack>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Notes</TableCell>
              <TableCell>Added By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {flattenedData.slice(startIndex, endIndex).map((entry, index) => (
              <TableRow key={index} data-testid={`notes-row-${index}`}>
                <TableCell>{entry.updatedAt}</TableCell>
                <TableCell>{entry.content}</TableCell>
                <TableCell>{entry.updatedBy}</TableCell>
              </TableRow>
            ))}
            {flattenedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center" data-testid="notes-no-data-message">
                  {isLoading ? (
                    <Skeleton variant={'rounded'} width={'100%'} height={'2rem'} />
                  ) : (
                    <Box data-testid={'no-data-message'}>No data available</Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {isAddingNote && (
        <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ padding: '24px', borderLeft: 'solid 3px var(--blue-light-500)' }}>
          <fieldset disabled={isCreatingComment} style={{ border: 'none', padding: 0, margin: 0, width: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: '16px' }}>
              <TextField
                id="content"
                autoFocus
                sx={{ flexGrow: 1, backgroundColor: 'white' }}
                data-testid="notes-input"
                label="New Note"
                variant="outlined"
                error={!!errors.content}
                helperText={errors.content?.message}
                {...register('content', {
                  required: 'Note content is required',
                  minLength: { value: 3, message: 'Note must be at least 3 characters long' }
                })}
                slotProps={
                  //@ts-expect-error - this is a valid prop, but missing from the definition
                  { input: { 'data-testid': 'note-input' } }
                }
              />
              <Button
                type="button"
                data-testid="notes-cancel"
                variant="outlined"
                onClick={handleCancelNote}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  color: '#4a4a4a',
                  borderColor: '#4a4a4a',
                  '&:hover': {
                    borderColor: '#4a4a4a',
                    backgroundColor: 'rgba(74, 74, 74, 0.04)'
                  }
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                type="submit"
                data-testid="notes-submit"
                variant="contained"
                loading={isCreatingComment}
                disabled={!noteContent || noteContent.trim() === ''}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  backgroundColor: '#1a2b3c',
                  '&:hover': {
                    backgroundColor: '#152736'
                  }
                }}
              >
                Save
              </LoadingButton>
            </Box>
          </fieldset>
        </Box>
      )}
      {totalItems > 0 && (
        <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} p={'.5rem'}>
          <Typography variant="body2" className="pagination-info" data-testid="note-pagination-info">
            {startIndex + 1}-{endIndex} of {totalItems}
          </Typography>
          {totalPages > 1 && <Pagination count={totalPages} page={page} onChange={handleChangePage} color="primary" data-testid="pagination" />}
        </Stack>
      )}
      <Dialog
        open={showConfirmDialog || blocker.state === 'blocked'}
        onClose={() => setShowConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Unsaved Changes'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to leave the unsaved changes? Leaving will not save any changes you had updated.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmCancel} color="error" autoFocus data-testid={'dialog-leave'}>
            Cancel Without Saving
          </Button>
          <Button onClick={handleCancelCancel} color="primary" data-testid={'dialog-stay'}>
            Keep Working
          </Button>
        </DialogActions>
      </Dialog>
    </Paper>
  )
}

export { Notes }
