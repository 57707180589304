import { FC, ReactNode } from 'react'
import ExclamationIcon from '../../assets/images/exclamation-mark-inside-orange-circle.svg?react'
import { Box, Stack, StackProps, Typography } from '@mui/material'

/**
 * Type of the props for the `ErrorScreen` component.
 */
type ErrorScreenProps = {
  title: ReactNode
  message: ReactNode
  details?: ReactNode
} & StackProps

/**
 * A component that displays an error screen with a title, message, and optionally detailed information about the error.
 */
export const ErrorScreen: FC<ErrorScreenProps> = ({ title, message, details, ...stackProps }) => {
  return (
    <Stack alignItems={'center'} justifyContent={'center'} spacing={'1rem'} useFlexGap {...stackProps}>
      <Box marginBottom={'.5rem'}>
        <ExclamationIcon />
      </Box>
      <Typography variant={'h2'} fontWeight={500} fontSize={24}>
        {title}
      </Typography>
      <Box fontWeight={300} fontSize={24}>
        {message}
      </Box>
      {!!details && <Typography>{details}</Typography>}
    </Stack>
  )
}
