import { FC, useRef, useState, ReactNode } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries.ts'
import { Box, Stack } from '@mui/material'
import { LocationSearch, LocationSearchProps } from '../../pages/PermitPage/LocationSearch.tsx'
import { Skeleton } from '../../components/Skeleton/Skeleton.tsx'
import { LocationCard } from '@simplisafe/blueprint-web-dsl'
import { Page, PageProps } from '../../components/Page'
import { useResizeEffect } from '../../hooks/useResizeEffect.ts'
import { ErrorScreen } from '../../components/ErrorScreen'
import { getCrmUrl, getCsrPageUrl, getResidenceType, getServicePageUrl } from '../../utils/permitUtils.ts'

export type LocationLayoutProps = LocationSearchProps &
  PageProps & {
    sidebar?: ReactNode
  }

export const LocationLayout: FC<LocationLayoutProps> = ({ title, onSidChanged, sidebar }) => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data

  const ref = useRef<HTMLDivElement>(null)

  const [locationCardHeight, setLocationCardHeight] = useState<number | undefined>()

  useResizeEffect(() => {
    if (location && ref.current) {
      setLocationCardHeight(ref.current.getBoundingClientRect().height)
    }
  }, [location])

  const height = locationCardHeight ? `${locationCardHeight}px` : '5.25rem'

  return (
    <Page title={title} sx={{ padding: 0 }}>
      <Stack direction={'row'} height="100%">
        {sidebar}
        <Stack flexGrow={1} spacing={'2rem'} useFlexGap p={{ xs: '1rem 2rem', md: '2rem 4rem' }}>
          <Stack direction={'row'} alignItems={'start'} spacing={'2rem'} useFlexGap flexWrap={{ xs: 'wrap', lg: 'nowrap' }}>
            <Box marginTop={'1rem'}>
              <LocationSearch onSidChanged={onSidChanged} />
            </Box>
            <Box flexGrow={'1'} position={'relative'} sx={{ height }} minWidth={'18rem'}>
              <Skeleton isLoading={locationQuery.isLoading} variant={'rounded'} sx={{ height }}>
                {location && (
                  <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0 }}>
                    <LocationCard
                      location={location}
                      crmUrl={getCrmUrl(location)}
                      csrUrl={getCsrPageUrl(location)}
                      servicePageUrl={getServicePageUrl(location)}
                      residenceType={getResidenceType(location)}
                      sx={{ zIndex: '100' }}
                      ref={ref}
                    />
                  </Box>
                )}
              </Skeleton>
            </Box>
          </Stack>
          {locationQuery.isError ? (
            <ErrorScreen title="Oops, sorry this SID or System Number could not be found!" message={'A refresh may fix this issue.'} flexGrow={1} />
          ) : (
            <Outlet />
          )}
        </Stack>
      </Stack>
    </Page>
  )
}
