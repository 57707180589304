import { EnqueueSnackbar, ProviderContext, useSnackbar as useNotistackSnackbar } from 'notistack'

export type UseSnackbarResult = ProviderContext & {
  toast: EnqueueSnackbar
}

export function useSnackbar(): UseSnackbarResult {
  const providerContext = useNotistackSnackbar()

  return { ...providerContext, toast: providerContext.enqueueSnackbar }
}
