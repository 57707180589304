import { FC } from 'react'
import { Skeleton } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries.ts'
import { Changelog } from './Changelog.tsx'
import { PermitsTable } from './PermitsTable'
import { Notes } from './Notes.tsx'
import { BannerError } from './BannerError.tsx'
import { READ_PERMITTING_COMMENTS_SCOPE } from '../../constants'
import { AuthCheck } from '../../components/AuthCheck'

export const PermitPage: FC = () => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data

  return (
    <>
      {location && <BannerError location={location} />}
      {location && (
        <>
          <PermitsTable location={location} />
          <Changelog location={location} />
          <AuthCheck has={READ_PERMITTING_COMMENTS_SCOPE}>
            <Notes location={location} />
          </AuthCheck>
        </>
      )}
      {locationQuery.isLoading && (
        <>
          <Skeleton variant={'rounded'} sx={{ height: '16rem', width: '100%' }} />
          <Skeleton variant={'rounded'} sx={{ height: '12rem', width: '100%' }} />
          <Skeleton variant={'rounded'} sx={{ height: '8rem', width: '100%' }} />
        </>
      )}
    </>
  )
}
