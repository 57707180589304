import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { LocationLayout } from '../LocationLayout'

export const FeatureViewerLayout: FC = () => {
  const navigate = useNavigate()

  const onSidChanged = (sid: string) => {
    navigate(`./location-features/${sid ?? ''}`)
  }

  return <LocationLayout title={'Location Feature Viewer'} onSidChanged={onSidChanged} />
}
