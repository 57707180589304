import { FC } from 'react'
import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Location } from '../../api'
import { PermitRow } from './PermitRow.tsx'
import { getPermitManagedByDisplayString } from '../../utils/permitUtils.ts'

type PermitsTableProps = {
  location: Location
}

/**
 * PermitsTable component.
 * Renders a permits table filled with permit-related data.
 */
export const PermitsTable: FC<PermitsTableProps> = ({ location }) => {
  return (
    <Paper data-testid="permits">
      <Stack direction={'row'} justifyContent={'space-between'}>
        <Typography variant={'sectionHeading'} p={'1rem'}>
          Permits
        </Typography>
        <Typography p={'1rem'} data-testid={'permit-managed-by'}>
          {getPermitManagedByDisplayString(location)}
        </Typography>
      </Stack>
      <TableContainer>
        <Table
          sx={{
            '.selected-indicator': { position: 'relative' },
            '.selected-indicator::after': {
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              width: '3px',
              content: '" "',
              bgcolor: 'var(--blue-light-500)'
            }
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>TYPE</TableCell>
              <TableCell align="left">NUMBER</TableCell>
              <TableCell align="left">EXPIRATION</TableCell>
              <TableCell align="left">REQUIREMENT</TableCell>
              <TableCell align="left">APPLICATION</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <PermitRow agencyType={'fire'} location={location} />
            <PermitRow agencyType={'police'} location={location} />
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
