import { FC } from 'react'
import { Sidebar } from '../../components/Sidebar'
import { useMatch, useNavigate } from 'react-router-dom'
import { LocationLayout } from '../LocationLayout'

/**
 * The core layout for the permit and related pages
 */
export const PermitLayout: FC = () => {
  const navigate = useNavigate()

  const matchPermits = useMatch('/permits/:sid?')

  const onSidChanged = (sid: string) => {
    navigate(`./${matchPermits ? 'permits' : 'jurisdiction-details'}/${sid ?? ''}`)
  }

  return <LocationLayout title={'Permit Management System'} onSidChanged={onSidChanged} sidebar={<Sidebar />} />
}
