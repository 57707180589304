import { FC, useState, MouseEvent } from 'react'
import { AppBar, Avatar, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Typography } from '@mui/material'
import { Logout } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'

interface HeaderProps {
  headerText?: string
  userInitials: string
}

export const Header: FC<HeaderProps> = ({ headerText, userInitials }) => {
  const { logout } = useAuth0()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    handleClose()
  }

  return (
    <AppBar position="static">
      <Toolbar
        sx={{
          minHeight: '40px',
          padding: '1.5rem 3rem',
          display: 'flex',
          alignItems: 'center',
          gap: '1rem'
        }}
      >
        <Typography
          component="h1"
          sx={{
            color: 'white',
            flexGrow: 1,
            fontSize: '28px',
            fontWeight: '700',
            fontStyle: 'normal',
            lineHeight: '28px'
          }}
        >
          {headerText}
        </Typography>
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Avatar
            sx={{
              backgroundColor: 'var(--bg-illustration)',
              fontSize: '1rem',
              fontWeight: '700',
              color: 'var(--text-primary)'
            }}
          >
            {userInitials}
          </Avatar>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
