import { FC, ReactNode } from 'react'
import { useAuthenticationContext } from '../../auth/AuthenticationContextProvider.tsx'
import { UnauthorizedPage } from '../../pages/UnauthorizedPage'

type RouteGuardProps = {
  has: string[]
  mode?: 'all' | 'any'
  element: ReactNode
}

/**
 * Will only render an outlet if the user has the required scopes.
 * Otherwise, the user will see the UnauthorizedPage.
 */
export const RouteGuard: FC<RouteGuardProps> = ({ has, mode = 'all', element }) => {
  const authContext = useAuthenticationContext()

  const hasAccess = mode === 'all' ? authContext.hasAllScopes(has) : authContext.hasAnyScope(has)

  return hasAccess ? element : <UnauthorizedPage />
}
