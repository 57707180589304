import { LocationPermits } from '../api'

export type AgencyTypeValues = Exclude<keyof LocationPermits, 'legacy' | 'medical'>

export enum AgencyType {
  fire = 'fire',
  police = 'police'
}

export const SupportedAgencyTypes = Object.values(AgencyType) as AgencyTypeValues[]
