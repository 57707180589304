export const ResidenceType = {
  Apartment: 1,
  House: 2,
  Business: 3
} as const

//TODO - update this in museum to be an enum, then reference the type generated from the swagger instead of this line.
export type ResidenceTypeValues = (typeof ResidenceType)[keyof typeof ResidenceType] // 1 | 2 | 3

export const ResidenceTypeDisplayNames: Record<ResidenceTypeValues, string> = {
  [ResidenceType.Apartment]: 'Apartment',
  [ResidenceType.House]: 'House',
  [ResidenceType.Business]: 'Business'
}
