import { TableCell, TableRow } from '@mui/material'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

interface AlarmTableEntryProps {
  alarmId: string
  category: string
  timestamp: string
}

export const AlarmTableEntry: FC<AlarmTableEntryProps> = ({ alarmId, category, timestamp }) => {
  const navigate = useNavigate()

  const humanTimestamp = new Date(timestamp).toLocaleString()

  return (
    <TableRow onClick={() => navigate(`./${alarmId}`)} data-testid="alarm-table-entry">
      <TableCell align="left">{humanTimestamp}</TableCell>
      <TableCell align="left">{category}</TableCell>
      <TableCell align="left">{alarmId}</TableCell>
    </TableRow>
  )
}
