import { FC } from 'react'
import { Link } from '@mui/material'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { Location } from '../../api'
import { getPermitOrdinanceUrl } from '../../utils/permitUtils.ts'

export const OrdinanceLink: FC<{ location: Location; agencyType: AgencyTypeValues }> = ({ agencyType, location }) => {
  const url = getPermitOrdinanceUrl(location, agencyType)

  if (url) {
    return (
      <Link href={url} target={'_blank'} rel="noopener">
        Ordinance
      </Link>
    )
  } else {
    return <>Not available</>
  }
}
