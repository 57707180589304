import { AgencyPermitRequirementValues, Location, LocationPermit, LocationPermitStatus } from '../api'
import { AgencyTypeValues, SupportedAgencyTypes } from '../constants/AgencyType.ts'
import {
  AgencyPermitRequirementDisplayNames,
  AgencyPermitRequirements,
  LOCATION_INFO_LINKS,
  PERMIT_LOCATION_INFO_LINKS,
  PermitSource,
  ResidenceType,
  ResidenceTypeDisplayNames,
  ResidenceTypeValues
} from '../constants'
import { formatIsoDate } from './dateUtils.ts'
import { DateTime } from 'luxon'
import { AlertColor } from '@mui/material'
import { getConfigurationService } from '../configuration'

export function hasStatusAction(location: Location, agencyType: AgencyTypeValues): boolean {
  const permit = location.permits ? location.permits[agencyType] : undefined
  const statusObject = permit?.status ?? {}
  return Object.values(statusObject).some(value => value) || isMissingPermit(location, agencyType)
}

function getActiveStatuses(location: Location, agencyType: AgencyTypeValues): (keyof LocationPermitStatus)[] {
  const statusObject = location.permits ? (location.permits[agencyType]?.status ?? {}) : {}
  return Object.entries(statusObject)
    .filter(entry => entry[1])
    .map(entry => entry[0] as keyof LocationPermitStatus)
}

type BannerErrorRule = {
  ruleName: string // this is only used for testing purposes
  priority: number
  color: AlertColor
  title: string
  body: string
  triggerWhen: {
    hasStatus: (keyof LocationPermitStatus)[] | 'n/a'
    meetsCondition: (location: Location, agencyType: AgencyTypeValues) => boolean
  }
}

export const bannerErrorRules: BannerErrorRule[] = [
  {
    // if a flag is set, but the location has not changed, then we show this message
    ruleName: 'CUSTOMER_REVIEW_NEEDED',
    priority: 3,
    color: 'error',
    title: 'Customer Review Needed',
    body: 'There was a recent change - awaiting customer review.',
    triggerWhen: {
      hasStatus: ['hasJurisdictionChanged', 'haveAgencyRulesChanged', 'hasJurisdictionPolicyChanged', 'hasLocationOwnershipChanged'],
      meetsCondition: (location: Location, agencyType: AgencyTypeValues) => permitCurrentlyManagedBy(location, agencyType) === PermitSource.Customer
    }
  },
  {
    // if a flag is set, but the location has not changed, then we show this message
    ruleName: 'REVIEW_NEEDED',
    priority: 2,
    color: 'error',
    title: 'Review Needed',
    body: 'There was a recent change, please make sure permit information is still accurate or update as necessary.',
    triggerWhen: {
      hasStatus: ['hasJurisdictionChanged', 'haveAgencyRulesChanged', 'hasJurisdictionPolicyChanged', 'hasLocationOwnershipChanged'],
      meetsCondition: (location: Location, agencyType: AgencyTypeValues) => permitCurrentlyManagedBy(location, agencyType) === PermitSource.SimpliSafe
    }
  },
  {
    // if we know the jurisdiction, but we don't have permit requirements for that jurisdiction then we show this message
    ruleName: 'UNABLE_TO_RETRIEVE_REQUIREMENTS',
    priority: 1,
    color: 'error',
    title: 'Unable To Retrieve Requirements',
    body: 'Please reach out to the jurisdiction for permit requirements.',
    triggerWhen: {
      hasStatus: 'n/a',
      meetsCondition: (location: Location, agencyType: AgencyTypeValues) =>
        (location.jurisdiction && location.jurisdiction?.agencyRules?.[agencyType]?.permitRequirements == null) ?? false
    }
  },
  {
    // this is for when someone moves
    ruleName: 'PERMIT_REQUIREMENTS_BEING_RETRIEVED_72H',
    priority: 5,
    color: 'info',
    title: 'Permit Requirements Being Retrieved - This Could Take Up To 72 Hours',
    body: 'Location is waiting for jurisdiction assignment.',
    triggerWhen: {
      hasStatus: 'n/a',
      meetsCondition: (location: Location) => !location.jurisdiction
    }
  },
  {
    // this is for when someone is setting up their system for the first time
    ruleName: 'PERMIT_REQUIREMENTS_BEING_RETRIEVED',
    priority: 4,
    color: 'info',
    title: 'Permit Requirements Being Retrieved',
    body: 'Location is waiting for jurisdiction assignment. Permit data below may be for a previous jurisdiction.',
    triggerWhen: {
      hasStatus: ['hasAddressChanged', 'hasSubscriptionReactivated', 'hasLocationTypeChanged'],
      meetsCondition: (location: Location) => !location.jurisdiction
    }
  }
]

/**
 * This function looks at the permit statuses and other conditions which would trigger a banner and chooses the right banner to show
 * @param location
 */
export function getBannerErrorMessage(location: Location): BannerErrorRule | undefined {
  const errorMessages: BannerErrorRule[] = []

  for (const agencyType of SupportedAgencyTypes) {
    // loop through the rules and check if the location meets the conditions for each rule
    for (const bannerErrorRule of bannerErrorRules) {
      if (bannerErrorRule.triggerWhen.meetsCondition(location, agencyType) && permitHasErrorStatus(location.permits?.[agencyType], bannerErrorRule)) {
        errorMessages.push(bannerErrorRule)
      }
    }
  }

  // return only the highest priority error message
  errorMessages.sort((a, b) => a.priority - b.priority)
  return errorMessages[0]
}

function permitHasErrorStatus(permit: LocationPermit | undefined, bannerError: BannerErrorRule): boolean {
  if (bannerError.triggerWhen.hasStatus == 'n/a') {
    return true
  }

  if (permit) {
    for (const status of bannerError.triggerWhen.hasStatus) {
      const permitStatus = permit.status ?? {}
      if (permitStatus[status]) {
        return true
      }
    }
  }

  return false
}

function isResidentialLocation(location: Location): boolean {
  return location.residenceType === ResidenceType.Apartment || location.residenceType === ResidenceType.House
}

export function displayAgencyPermitRequirement(location: Location, agencyType: AgencyTypeValues): string {
  return AgencyPermitRequirementDisplayNames[getAgencyPermitRequirement(location, agencyType)]
}

export function getPermitRegistrationUrl(location: Location, agencyType: AgencyTypeValues) {
  return location.jurisdiction?.agencyRules[agencyType]?.permitRegistrationUrl
}

export function getPermitOrdinanceUrl(location: Location, agencyType: AgencyTypeValues): string {
  return location.jurisdiction?.agencyRules[agencyType]?.permitOrdinanceUrl ?? ''
}

export function getAgencyPermitRequirement(location: Location, agencyType: AgencyTypeValues): AgencyPermitRequirementValues {
  if (isResidentialLocation(location)) {
    return location.jurisdiction?.agencyRules[agencyType]?.permitRequirements.residential?.requirement ?? AgencyPermitRequirements.Unknown
  } else {
    return location.jurisdiction?.agencyRules[agencyType]?.permitRequirements.commercial?.requirement ?? AgencyPermitRequirements.Unknown
  }
}

export function isMissingPermit(location: Location, agencyType: AgencyTypeValues) {
  const permitRequired = permitIsRequired(agencyType, location)

  const hasPermit = location.permits ? !!location.permits[agencyType] : false
  return !hasPermit && permitRequired
}

const permitStatusChangeDescriptions: Record<keyof LocationPermitStatus, string> = {
  hasAddressChanged: 'Address',
  hasSubscriptionReactivated: 'Reactivated',
  hasLocationTypeChanged: 'Location Type',
  hasJurisdictionChanged: 'Permit Jurisdiction',
  haveAgencyRulesChanged: 'Agency Rules',
  hasJurisdictionPolicyChanged: 'Jurisdiction Policy',
  hasLocationOwnershipChanged: 'Location Ownership'
}

export function getStatusActionPrompt(location: Location, agencyType: AgencyTypeValues): string {
  if (isMissingPermit(location, agencyType)) {
    return 'Missing Permit. Please provide permit information.'
  }

  const fieldDescriptions = getActiveStatuses(location, agencyType).map(fieldName => permitStatusChangeDescriptions[fieldName])

  const managedBy = permitCurrentlyManagedBy(location, agencyType)
  if (fieldDescriptions.length > 0) {
    return `${managedBy === PermitSource.Customer ? 'Awaiting customer review. ' : ''}Location had the following changes: ${fieldDescriptions.join(', ')}.`
  } else {
    return ''
  }
}

export function getStatusActionConfirmation(location: Location, agencyType: AgencyTypeValues): string {
  const managedBy = permitCurrentlyManagedBy(location, agencyType)
  return `Confirm${managedBy === PermitSource.Customer ? ' on behalf of the customer ' : ' '}if permit is still accurate.`
}

/**
 * This function displays to the user who needs is responsible for permitting registration and renewal for the location
 * @param location
 */
export function getPermitManagedByDisplayString(location: Location): string {
  const registeredBy = location.jurisdiction?.policies.registration.isSimpliSafeManaged ? PermitSource.SimpliSafe : PermitSource.Customer
  const renewedBy = location.jurisdiction?.policies.renewal.isSimpliSafeManaged ? PermitSource.SimpliSafe : PermitSource.Customer
  if (!location.jurisdiction) {
    return 'Assigning Jurisdiction'
  } else if (registeredBy === renewedBy) {
    return `${registeredBy} Registration + Renewal`
  } else {
    return `${registeredBy} Registration + ${renewedBy} Renewal`
  }
}

/**
 * function lets you know who needs to own the next permitting action
 * @param location - The jurisdictional rules for the permit.
 * @param agencyType - The type of the agency that the permit falls under.
 * @returns
 */
export function permitCurrentlyManagedBy(location: Location, agencyType: AgencyTypeValues): PermitSource | undefined {
  if (location.jurisdiction) {
    const hasPermit = location.permits ? !!location.permits[agencyType] : false
    if (hasPermit) {
      return location.jurisdiction.policies.renewal.isSimpliSafeManaged ? PermitSource.SimpliSafe : PermitSource.Customer
    } else {
      return location.jurisdiction.policies.registration.isSimpliSafeManaged ? PermitSource.SimpliSafe : PermitSource.Customer
    }
  }
}

export function getExpirationDateText(location: Location, agencyType: AgencyTypeValues): string | undefined {
  const permit = location.permits ? location.permits[agencyType] : undefined
  if (!permit) {
    return 'No permit registered'
  } else if (permitDoesNotExpire(location, agencyType)) {
    return 'Registered - no expiration'
  } else {
    return formatIsoDate(permit.expirationDate)
  }
}

export function getPermitNumber(permit: LocationPermit | undefined) {
  if (!permit) {
    return 'No permit registered'
  } else if (permit.isNotNumbered || !permit.permitNumber) {
    return 'Registered - no number'
  } else {
    return permit.permitNumber
  }
}

export function permitIsRequired(agencyType: AgencyTypeValues, location: Location): boolean {
  return getAgencyPermitRequirement(location, agencyType) === AgencyPermitRequirements.Required
}

export function permitIsExpired(location: Location, agencyType: AgencyTypeValues): boolean {
  const permit = location.permits ? location.permits[agencyType] : undefined
  if (!permit || permit?.expirationDate === undefined || permitDoesNotExpire(location, agencyType)) {
    return false
  }

  const expirationDate = DateTime.fromISO(permit.expirationDate)
  const today = DateTime.now()
  return expirationDate.startOf('day') < today.startOf('day')
}

export function permitDoesNotExpire(location: Location, agencyType: AgencyTypeValues): boolean {
  const permitRenewal = location.jurisdiction?.agencyRules[agencyType]?.permitRenewal
  if (permitRenewal) {
    // if we have a permit renewal rule, then it overrides the isEternal legacy flag
    return !permitRenewal?.isRequired
  } else {
    return location.permits?.[agencyType]?.isEternal ?? false
  }
}

export function getResidenceType(location: Location): string {
  return ResidenceTypeDisplayNames[location.residenceType as ResidenceTypeValues]
}

export function getCrmUrl(location: Location): string {
  return PERMIT_LOCATION_INFO_LINKS.CRM_PAGE(location.user.userId, location.id, getConfigurationService().environmentDetails.name)
}

export function getServicePageUrl(location: Location): string {
  return LOCATION_INFO_LINKS.SERVICE_PAGE(location.id, getConfigurationService().environmentDetails.name)
}

export function getCsrPageUrl(location: Location): string {
  return LOCATION_INFO_LINKS.CSR_PAGE(location.user.userId, getConfigurationService().environmentDetails.name)
}
