import { PermitRenewalCadenceValues } from '../api'

export enum PermitRenewalCadence {
  None = 'NONE',
  Annually = 'ANN',
  Quarterly = 'Quarterly',
  OddYears = 'Odd Years',
  EvenYears = 'Even Years',
  FalseAlarmLimitReached = 'FAlimit',
  January = 'JAN',
  February = 'FEB',
  March = 'MAR',
  April = 'APR',
  May = 'MAY',
  June = 'JUN',
  July = 'JUL',
  August = 'AUG',
  September = 'SEP',
  October = 'OCT',
  November = 'NOV',
  December = 'DEC',
  Unknown = 'Unknown'
}

export const PermitRenewalCadenceDisplayNames: Record<PermitRenewalCadenceValues, string> = {
  [PermitRenewalCadence.None]: 'None',
  [PermitRenewalCadence.Annually]: 'Annually',
  [PermitRenewalCadence.Quarterly]: 'Quarterly',
  [PermitRenewalCadence.OddYears]: 'Odd Years',
  [PermitRenewalCadence.EvenYears]: 'Even Years',
  [PermitRenewalCadence.FalseAlarmLimitReached]: 'False Alarm Limit Reached',
  [PermitRenewalCadence.January]: 'January',
  [PermitRenewalCadence.February]: 'February',
  [PermitRenewalCadence.March]: 'March',
  [PermitRenewalCadence.April]: 'April',
  [PermitRenewalCadence.May]: 'May',
  [PermitRenewalCadence.June]: 'June',
  [PermitRenewalCadence.July]: 'July',
  [PermitRenewalCadence.August]: 'August',
  [PermitRenewalCadence.September]: 'September',
  [PermitRenewalCadence.October]: 'October',
  [PermitRenewalCadence.November]: 'November',
  [PermitRenewalCadence.December]: 'December',
  [PermitRenewalCadence.Unknown]: 'Unknown'
}
