import { FC } from 'react'
import { Location } from '../../api'
import { Chip } from '@mui/material'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { permitIsExpired } from '../../utils/permitUtils.ts'

type PermitExpiredCellContentsProps = {
  agencyType: AgencyTypeValues
  location: Location
}

export const PermitExpiredCellContents: FC<PermitExpiredCellContentsProps> = ({ agencyType, location }) => {
  return permitIsExpired(location, agencyType) && <Chip label="Expired" data-testid="permit-expired-chip" color="error" />
}
