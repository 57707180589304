import { useNavigate, useParams } from 'react-router-dom'
import { useLocationAlarm } from '../../api/apiQueries'
import { FC } from 'react'
import { Box, Link, Stack } from '@mui/material'
import { Skeleton } from '../../components/Skeleton/Skeleton'
import { AlarmSmsView } from './AlarmSmsView'
import { AlarmCard } from './AlarmCard'

export const AlarmSmsPage: FC = () => {
  const { sid, alarmId } = useParams()

  const alarmQuery = useLocationAlarm(sid, alarmId)
  const alarm = alarmQuery.data

  const navigate = useNavigate()

  return (
    <Stack>
      <Box alignSelf={'start'} paddingBottom={'4rem'}>
        <Link color="primary" onClick={() => navigate(`../alarm-sms-review/${sid}`)} data-testid="back-to-all-alarms-button">
          Back to All Alarms for Location '{sid}'
        </Link>
      </Box>
      {alarm ? (
        <AlarmCard alarm={alarm} />
      ) : (
        alarmQuery.isLoading && <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '4rem', width: '100%' }} />
      )}

      <Box paddingTop="10px">
        <AlarmSmsView />
      </Box>
    </Stack>
  )
}
