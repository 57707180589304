import { Component, ErrorInfo, FC, ReactNode } from 'react'
import { Location, useLocation } from 'react-router-dom'
import { ErrorScreen } from '../ErrorScreen'
import { Stack } from '@mui/material'

export type ErrorBoundaryProps = {
  location: Location
  children?: ReactNode
  fallback?: ReactNode
}

type ErrorBoundaryState = {
  hasError: boolean
}

export const ErrorBoundary: FC<Omit<ErrorBoundaryProps, 'location'>> = props => {
  const location = useLocation()

  return <ErrorBoundaryHelper location={location} {...props} />
}

class ErrorBoundaryHelper extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error(error, info.componentStack)
  }

  componentDidUpdate(prevProps: ErrorBoundaryProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ hasError: false })
    }
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback) {
        return this.props.fallback
      } else {
        return (
          <Stack minHeight={'100vh'} justifyContent={'center'}>
            <ErrorScreen title="Oops, we've hit a snag!" message={'A retry may fix this issue.'} />
          </Stack>
        )
      }
    }

    return this.props.children
  }
}
