import { FC, useState } from 'react'
import { Box, Stack, TextField } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useSystemNumberToSidFn } from '../../api/apiQueries.ts'
import LoadingButton from '@mui/lab/LoadingButton'

export type LocationSearchProps = {
  onSidChanged: (sid: string) => void
}

type Form = {
  sid: string
}

/**
 * SearchPanel component.
 * Renders a search panel with an input field and a search button.
 */
export const LocationSearch: FC<LocationSearchProps> = ({ onSidChanged }) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch
  } = useForm<Form>()

  const translateSystemNumberToSid = useSystemNumberToSidFn()

  function isSystemNumber(inputtedValue: string | number): boolean {
    if (typeof inputtedValue === 'string') {
      return inputtedValue.toUpperCase().startsWith('SSI')
    }
    return false
  }

  const onSubmit = async (form: Form) => {
    let sid = form.sid
    try {
      if (isSystemNumber(form.sid)) {
        setIsLoading(true)
        const translated = await translateSystemNumberToSid(form.sid)
        sid = translated.locationId
      }

      // only clear the input if the request is successful. If we throw, then this will get skipped.
      setValue('sid', '')
    } catch (e) {
      // we don't want to leak an uncaught error to fail our test
      console.log(e)
    } finally {
      // as long as it passes validation we want to call the onSidChanged callback... even if the request fails
      onSidChanged(sid)
      setIsLoading(false)
    }
  }

  return (
    <Stack component={'form'} onSubmit={handleSubmit(onSubmit)} data-testid={'searchPanelForm'} direction="row" spacing={'2rem'} alignItems={'start'}>
      <TextField
        id="sid"
        label={'SID or System Number'}
        error={!!errors.sid}
        helperText={errors.sid?.message}
        autoComplete={'off'}
        slotProps={{
          inputLabel: { shrink: !!watch('sid') || isFocused },
          htmlInput: {
            // this turns off onepassword and lastpass autofill
            'data-1p-ignore': true,
            'data-lpignore': true,
            'data-testid': 'sid'
          }
        }}
        sx={{ inlineSize: '210px' }}
        {...register('sid', {
          required: 'SID or System Number is required',
          pattern: { value: /^(SSI\d+|\d+)$/i, message: 'Must be numeric or start with SSI' }
        })}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <Box>
        <LoadingButton
          loading={isLoading}
          variant={'contained'}
          color={'primary'}
          type="submit"
          data-testid="searchButton"
          sx={{ marginTop: '.5rem' }}
        >
          Search
        </LoadingButton>
      </Box>
    </Stack>
  )
}
