import { FC, PropsWithChildren } from 'react'
import { useAuthenticationContext } from '../../auth/AuthenticationContextProvider.tsx'

type AuthCheckProps = {
  has: string[] | string
  mode?: 'all' | 'any'
}

/**
 * Will only render an outlet if the user has the required scopes.
 * Otherwise, the user will see the UnauthorizedPage.
 */
export const AuthCheck: FC<PropsWithChildren<AuthCheckProps>> = ({ has, mode = 'all', children }) => {
  const authContext = useAuthenticationContext()

  const scopes = Array.isArray(has) ? has : [has]

  const hasAccess = mode === 'all' ? authContext.hasAllScopes(scopes) : authContext.hasAnyScope(scopes)

  return hasAccess ? children : null
}
