import { FC, useState } from 'react'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { Location } from '../../api'
import { Button, Stack, TableCell, TableRow } from '@mui/material'
import { PermitRowEditor } from './PermitRowEditor.tsx'
import {
  displayAgencyPermitRequirement,
  getExpirationDateText,
  getPermitNumber,
  getStatusActionPrompt,
  hasStatusAction
} from '../../utils/permitUtils.ts'
import EditIcon from '@mui/icons-material/Edit'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { ALL_PERMITTING_SCOPES } from '../../constants'
import { useAuthenticationContext } from '../../auth/AuthenticationContextProvider.tsx'
import { AppFormLink } from './AppFormLink.tsx'
import { PermitExpiredCellContents } from './PermitExpiredCellContents.tsx'

export const PermitRow: FC<{ agencyType: AgencyTypeValues; location: Location }> = ({ agencyType, location }) => {
  const permit = location.permits ? location.permits[agencyType] : undefined

  const [editingPermit, setEditingPermit] = useState<boolean>()
  const authContext = useAuthenticationContext()
  const userCanEditPermits = authContext.hasAllScopes(ALL_PERMITTING_SCOPES)

  if (editingPermit) {
    return (
      <PermitRowEditor
        location={location}
        agencyType={agencyType}
        permit={permit}
        onCancel={() => setEditingPermit(false)}
        onOperationSuccess={() => setEditingPermit(false)}
      />
    )
  }

  return (
    <>
      {hasStatusAction(location, agencyType) && (
        <TableRow data-testid={`${agencyType}-permit-prompt`}>
          <TableCell colSpan={7} sx={{ bgcolor: 'var(--bg-alert)', color: 'var(--text-error)', p: '1rem 1.5rem', border: 'none' }}>
            <Stack direction="row" spacing={'.5rem'} alignItems="center" useFlexGap>
              <ErrorOutlineIcon />
              {getStatusActionPrompt(location, agencyType)}
            </Stack>
          </TableCell>
        </TableRow>
      )}
      <TableRow data-testid={`${agencyType}-permit-row`}>
        <TableCell>
          <PermitExpiredCellContents agencyType={agencyType} location={location} />
        </TableCell>
        <TableCell component="th" scope="row">
          {agencyType.charAt(0).toUpperCase() + agencyType.slice(1)}
        </TableCell>
        <TableCell align="left">{getPermitNumber(permit)}</TableCell>
        <TableCell align="left">{getExpirationDateText(location, agencyType)}</TableCell>
        <TableCell align="left" data-testid={'agency-permit-requirement'}>
          {displayAgencyPermitRequirement(location, agencyType)}
        </TableCell>
        <TableCell align="left">
          <AppFormLink agencyType={agencyType} location={location} />
        </TableCell>
        <TableCell align="right">
          <Button
            variant="text"
            startIcon={<EditIcon />}
            onClick={() => setEditingPermit(true)}
            data-testid="edit-permit-button"
            disabled={!userCanEditPermits}
          >
            Edit
          </Button>
        </TableCell>
      </TableRow>
    </>
  )
}
