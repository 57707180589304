import { ChangeEvent, FC, useMemo, useState } from 'react'
import { Box, Pagination, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { useChangelog } from '../../api/apiQueries.ts'
import { Location } from '../../api'
import { getChangeLogData } from './changelogUtils.ts'

interface ChangelogProps {
  location: Location
}

export const Changelog: FC<ChangelogProps> = ({ location }) => {
  const [page, setPage] = useState(1)
  const rowsPerPage = 4

  const { data: changelogData, isLoading } = useChangelog(location.id)

  const flattenedData = useMemo(() => {
    if (!changelogData) {
      return []
    }
    return getChangeLogData(changelogData, location)
  }, [changelogData, location])

  const totalItems = flattenedData.length
  const totalPages = Math.ceil(totalItems / rowsPerPage)
  const startIndex = (page - 1) * rowsPerPage
  const endIndex = Math.min(startIndex + rowsPerPage, totalItems)

  const handleChangePage = (event: ChangeEvent<unknown>, newPage: number) => {
    setPage(newPage)
  }

  return (
    <Paper data-testid={'changelog'}>
      <Typography variant={'sectionHeading'} p={'1rem'}>
        Changelog
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Expiration</TableCell>
              <TableCell>Change</TableCell>
              <TableCell>Change By</TableCell>
            </TableRow>
          </TableHead>
          <TableBody data-testid={'changelog-rows'}>
            {flattenedData.slice(startIndex, endIndex).map((entry, index) => (
              <TableRow key={index} className="changelog-table-row" data-testid={`changelog-row-${index}`}>
                <TableCell>{entry.updatedAt}</TableCell>
                <TableCell sx={{ textTransform: 'capitalize' }}>{entry.agencyType}</TableCell>
                <TableCell>{entry.permitNumber}</TableCell>
                <TableCell>{entry.expirationDate}</TableCell>
                <TableCell>{entry.changeText}</TableCell>
                <TableCell>{entry.updatedBy}</TableCell>
              </TableRow>
            ))}
            {flattenedData.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  {isLoading ? (
                    <Skeleton variant={'rounded'} width={'100%'} height={'2rem'} />
                  ) : (
                    <Box data-testid={'no-data-message'}>No data available</Box>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {totalItems > 0 && (
        <Stack direction="row" justifyContent={'flex-end'} alignItems={'center'} p={'.5rem'}>
          <Typography variant="body2" className="pagination-info" data-testid="pagination-info">
            {startIndex + 1}-{endIndex} of {totalItems}
          </Typography>
          {totalPages > 1 && <Pagination count={totalPages} page={page} onChange={handleChangePage} color="primary" data-testid="pagination" />}
        </Stack>
      )}
    </Paper>
  )
}
