import { FC, PropsWithChildren } from 'react'
import { Header } from './Header.tsx'
import { Box, Grid2 as Grid, Grid2Props } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { getUserInitials } from '../../utils/utils.ts'

export type PageProps = {
  title?: string
} & Grid2Props

export const Page: FC<PropsWithChildren<PageProps>> = ({ title, children, ...gridProps }) => {
  const { user } = useAuth0()
  const profileName = user?.name ?? ''
  const userInitials = getUserInitials(profileName)

  return (
    <Grid container display={'grid'} width={'100%'} minHeight={'100vh'} gridTemplateAreas={`"header" "content"`} gridTemplateRows={'auto 1fr'}>
      <Grid gridArea={'content'} sx={{ p: 'var(--spacing-l)' }} {...gridProps}>
        <Box sx={{ overflowX: 'auto', height: '100%' }}>{children}</Box>
      </Grid>

      {/*intentionally put header after the content in the DOM so that its shadow renders on top of the content without manipulating z-index */}
      <Grid gridArea={'header'}>
        <Header headerText={title} userInitials={userInitials} />
      </Grid>
    </Grid>
  )
}
