import { ApplicationEnvironment } from './ApplicationEnvironment.ts'

/**
 * Loads JSON config from an element on the index.html, which is injected server-side.
 *
 * @export
 * @return {ApplicationEnvironment} The Application Environment config.
 */
export function loadEnvironment(): ApplicationEnvironment {
  const inlineConfig = document.getElementById('inlineConfig')?.innerHTML || '{}'
  try {
    return JSON.parse(inlineConfig)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    throw new Error(`Could not load environment: '${error.message ?? error.toString()}'`)
  }
}
