import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from './App.tsx'
import { bool } from './utils/utils.ts'
import { theme } from './styles/theme.ts'
import CssBaseline from '@mui/material/CssBaseline'
import { getMode } from './utils/localStorageUtils.ts'
import { ThemeProvider } from '@mui/material/styles'
import { ColorScheme } from '@simplisafe/blueprint-web-dsl'
import { StyledSnackbarProvider } from './components/StyledSnackbarProvider'

const rootElement = document.getElementById('root')

if (rootElement === null) {
  throw new Error('The application could not start because the root element was not found.')
}

async function startMockServiceWorker(): Promise<void> {
  if (bool(import.meta.env.VITE_ENABLE_AUTO_MOCK) || bool(import.meta.env.VITE_ENABLE_CUSTOM_MSW_HANDLERS)) {
    const { getWorker } = await import('./msw/setup/setupForBrowser.ts')
    const worker = getWorker()
    await worker.start()
  }
}

const AppRoot = () => {
  const bpColorMode = getMode()

  return (
    <ThemeProvider theme={theme}>
      <ColorScheme modeOverride={bpColorMode}>
        <CssBaseline />
        <StyledSnackbarProvider>
          <App />
        </StyledSnackbarProvider>
      </ColorScheme>
    </ThemeProvider>
  )
}

function mountApp(rootElement: HTMLElement) {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <AppRoot />
    </React.StrictMode>
  )
}

startMockServiceWorker().then(() => {
  mountApp(rootElement)
})
