import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries.ts'
import { JurisdictionDetailsTable } from './JurisdictionDetailsTable.tsx'
import { Skeleton } from '@mui/material'

const JurisdictionDetailsPage: FC = () => {
  const { sid } = useParams()
  const locationQuery = useLocation(sid)
  const location = locationQuery.data

  return (
    <>
      {location && <JurisdictionDetailsTable location={location} />}
      {locationQuery.isLoading && <Skeleton variant={'rounded'} sx={{ height: '60rem', width: '100%' }} />}
    </>
  )
}

export { JurisdictionDetailsPage }
