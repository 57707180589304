import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { FC } from 'react'
import { AlarmSmsTableEntry } from './AlarmSmsTableEntry'
import { Contact, Message } from '../../api'

interface AlarmSmsTableProps {
  messages: Array<Message>
  contacts: Array<Contact>
}

export const AlarmSmsTable: FC<AlarmSmsTableProps> = ({ messages, contacts }) => {
  const entries = messages.map(message => {
    const contact = contacts.find(contact => contact.phoneNumber === message.phoneNumber)
    return <AlarmSmsTableEntry message={message} contactFirstName={contact?.firstName ?? 'Unknown'} />
  })

  return (
    <Paper data-testid="alarms">
      <Typography variant={'sectionHeading'} p={'1rem'} data-testid={'message-count'}>
        Messages ({messages.length})
      </Typography>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>TIMESTAMP</TableCell>
              <TableCell>CONTACT</TableCell>
              <TableCell>MESSAGE</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{entries}</TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
