import { ReactNode } from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'
import { RouteGuard } from './components/RouteGuard'
import { ALARM_REVIEW_APPLET_SCOPES, ALARM_SMS_REVIEW_APPLET_SCOPES, PERMITTING_APPLET_SCOPES } from './constants'
import { AppLayout } from './layouts/AppLayout'
import { FeatureViewerLayout } from './layouts/FeatureViewerLayout'
import { PermitLayout } from './layouts/PermitLayout'
import { AlarmSmsPage } from './pages/AlarmSmsPage'
import { AlarmsPage } from './pages/AlarmsPage'
import { FeatureViewerPage } from './pages/FeatureViewerPage'
import { JurisdictionDetailsPage } from './pages/JurisdictionDetailsPage'
import { LandingPage } from './pages/LandingPage'
import { NotFoundPage } from './pages/NotFoundPage'
import { PermitPage } from './pages/PermitPage'
import { UnauthorizedPage } from './pages/UnauthorizedPage'
import { AlarmSmsLayout } from './layouts/AlarmSmsLayout'

function guardedElement(element: ReactNode, scopes: string[], mode: 'all' | 'any' = 'all') {
  return {
    element: <RouteGuard element={element} has={scopes} mode={mode} />
  }
}

/**
 * Note: Top-level components need to render a Page Component
 */
export const routes: RouteObject[] = [
  {
    element: <AppLayout />,
    children: [
      { path: '/', element: <LandingPage /> },
      {
        ...guardedElement(<AlarmSmsLayout />, ALARM_SMS_REVIEW_APPLET_SCOPES, 'all'),
        children: [
          { path: 'alarm-sms-review/:sid?', element: <AlarmsPage /> },
          { path: 'alarm-sms-review/:sid/:alarmId?', element: <AlarmSmsPage /> }
        ]
      },
      {
        ...guardedElement(<FeatureViewerLayout />, ALARM_REVIEW_APPLET_SCOPES, 'all'),
        children: [{ path: 'location-features/:sid?', element: <FeatureViewerPage /> }]
      },
      {
        ...guardedElement(<PermitLayout />, PERMITTING_APPLET_SCOPES, 'all'),
        children: [
          { path: 'permits/:sid?', element: <PermitPage /> },
          { path: 'jurisdiction-details/:sid?', element: <JurisdictionDetailsPage /> }
        ]
      },
      { path: '/unauthorized', element: <UnauthorizedPage /> },
      { path: '*', element: <NotFoundPage /> }
    ]
  }
]

export const appRouter = createBrowserRouter(routes)
