import { FC } from 'react'
import { AlarmSmsTable } from './AlarmSmsTable'
import { Skeleton } from '../../components/Skeleton/Skeleton'
import { useAlarmSmsIncident } from '../../api/apiQueries'
import { useParams } from 'react-router-dom'

export const AlarmSmsView: FC = () => {
  const { sid, alarmId } = useParams()

  const smsIncidentQuery = useAlarmSmsIncident(sid, alarmId)
  const smsIncident = smsIncidentQuery.data

  const messages = smsIncident?.messages ?? []
  const contacts = smsIncident?.primaryContacts ?? []

  return (
    <>
      {smsIncident ? (
        <AlarmSmsTable messages={messages} contacts={contacts} />
      ) : (
        smsIncidentQuery.isLoading && (
          <>
            <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '8rem', width: '100%' }} />
            <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '16rem', width: '100%' }} />
          </>
        )
      )}
    </>
  )
}
