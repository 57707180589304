import { FC } from 'react'
import { Box, Link } from '@mui/material'
import { AgencyTypeValues } from '../../constants/AgencyType.ts'
import { Location } from '../../api'
import { getPermitRegistrationUrl } from '../../utils/permitUtils.ts'

export const AppFormLink: FC<{ location: Location; agencyType: AgencyTypeValues }> = ({ agencyType, location }) => {
  const url = getPermitRegistrationUrl(location, agencyType)

  if (url) {
    return (
      <Link href={url} target={'_blank'} rel="noopener">
        Application Form
      </Link>
    )
  } else {
    return <Box data-testid={'app-form-link-not-available'}>Not available</Box>
  }
}
