import { loadEnvironment } from './configurationUtils.ts'
import { ApplicationEnvironment } from './ApplicationEnvironment.ts'

/**
 * A service that provides access to the environment-specific configuration under which the application is running.
 */
class ConfigurationService {
  readonly #environment: ApplicationEnvironment

  constructor() {
    this.#environment = loadEnvironment()
  }

  /**
   * The environment details for the configuration.
   */
  get environmentDetails(): ApplicationEnvironment {
    return this.#environment
  }

  /**
   * The base URL of the instance of the Museum API used in the current environment.
   */
  get validateDatesWhileFormatting(): boolean {
    return this.#environment?.validateDatesWhileFormatting
  }

  /**
   * The base URL of the instance of the Museum API used in the current environment.
   */
  get museumEndpoint(): string {
    return this.#environment.endpoints.museum
  }

  /**
   * The base URL of the instance of the Partyline API used in the current environment.
   */
  get partylineEndpoint(): string {
    return this.#environment.endpoints.partyline
  }
}

let configurationService: ConfigurationService | undefined

export const getConfigurationService = () => {
  if (!configurationService) {
    configurationService = new ConfigurationService()
  }
  return configurationService
}
