import { AuthenticationContextValuesImpl } from './AuthenticationContextValues.ts'
import { ALL_SCOPES } from '../constants'

export const mockAuthContextValues: AuthenticationContextValuesImpl = new AuthenticationContextValuesImpl(undefined, ALL_SCOPES)

export const overrideUserScopes = (scopes: string[]) => {
  mockAuthContextValues.setTestScopeOverrides(scopes)
}

export const clearUserScopeOverrides = () => {
  mockAuthContextValues.clearTestScopeOverrides()
}
