import { FC, PropsWithChildren } from 'react'
import { closeSnackbar, MaterialDesignContent, SnackbarKey, SnackbarProvider, SnackbarProviderProps } from 'notistack'
import { IconButton, styled } from '@mui/material'
import { Close } from '@mui/icons-material'

/**
 * Will only render an outlet if the user has the required scopes.
 * Otherwise, the user will see the UnauthorizedPage.
 */
export const StyledSnackbarProvider: FC<PropsWithChildren<SnackbarProviderProps>> = ({ children, ...rest }) => {
  const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme }) => ({
    '&.notistack-MuiContent-success': {
      color: 'var(--text-primary-inverted)',
      backgroundColor: '#000',
      ...theme.applyStyles('dark', {
        backgroundColor: '#fff'
      })
    },
    '&.notistack-MuiContent-error': {
      color: 'var(--text-primary-inverted)',
      backgroundColor: '#000',
      ...theme.applyStyles('dark', {
        backgroundColor: '#fff'
      })
    },
    '&.notistack-MuiContent-info': {
      color: 'var(--text-primary-inverted)',
      backgroundColor: '#000',
      ...theme.applyStyles('dark', {
        backgroundColor: '#fff'
      })
    },
    '&.notistack-MuiContent-warning': {
      color: 'var(--text-primary-inverted)',
      backgroundColor: '#000',
      ...theme.applyStyles('dark', {
        backgroundColor: '#fff'
      })
    }
  }))

  return (
    <SnackbarProvider
      maxSnack={3}
      action={(key: SnackbarKey) => (
        <IconButton onClick={() => closeSnackbar(key)} aria-label={'dismiss'} sx={{ color: 'white' }}>
          <Close />
        </IconButton>
      )}
      Components={{
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent
      }}
      {...rest}
    >
      {children}
    </SnackbarProvider>
  )
}
