import { Grid2, Stack } from '@mui/material'
import { FC } from 'react'
import { useParams } from 'react-router-dom'
import { useLocation } from '../../api/apiQueries'
import { Skeleton } from '../../components/Skeleton/Skeleton'
import { FeatureCard } from './FeatureCard'

export const FeatureViewerPage: FC = () => {
  const { sid } = useParams()

  const locationQuery = useLocation(sid)
  const location = locationQuery.data

  return (
    <Stack>
      <Grid2 data-testid={'feature-card-grid'} container spacing={2} columns={12}>
        {location
          ? location.features.map(feature => (
              <Grid2 size={4}>
                <FeatureCard feature={feature} />
              </Grid2>
            ))
          : [locationQuery.isLoading && <Skeleton isLoading={true} variant={'rounded'} sx={{ height: '4rem', width: '100%' }} />]}
      </Grid2>
    </Stack>
  )
}
