import { Location, LocationPermitHistories } from '../../api'
import { formatIsoDate, formatIsoTimestampForLocation } from '../../utils/dateUtils.ts'
import _ from 'lodash'

interface PermitChangeLogData {
  agencyType: string
  expirationDate: string
  updatedAt: string
  updatedBy: string
  permitNumber: string
  changeText: string
}

enum PermitChangeType {
  Created = 'Permit added.',
  Updated = 'Permit updated.',
  UpdatedPermitNumber = 'Permit number updated.',
  UpdatedExpiration = 'Permit expiration updated.',
  Deleted = 'Permit removed.'
}

const convertChangeTypeText = (changeType: string): string => {
  switch (changeType) {
    case 'Created': {
      return PermitChangeType.Created
    }
    case 'Deleted': {
      return PermitChangeType.Deleted
    }
    case 'UpdatedExpiration': {
      return PermitChangeType.UpdatedExpiration
    }
    case 'UpdatedPermitNumber': {
      return PermitChangeType.UpdatedPermitNumber
    }
    default: {
      return PermitChangeType.Updated
    }
  }
}

export const getChangeLogData = (permitHistory: LocationPermitHistories, location: Location): PermitChangeLogData[] => {
  const historyResults: PermitChangeLogData[] = []
  let key: keyof LocationPermitHistories
  for (key in permitHistory) {
    const historySet = permitHistory[key]

    if (historySet) {
      for (const item of historySet) {
        const change: PermitChangeLogData = {
          agencyType: key,
          expirationDate: 'N/A',
          updatedAt: formatIsoTimestampForLocation(item.updatedAt, location),
          updatedBy: item.updatedBy,
          permitNumber: 'N/A',
          changeText: convertChangeTypeText(item.changeType)
        }

        if ('expirationDate' in item) {
          change.expirationDate = item.expirationDate ? formatIsoDate(item.expirationDate) : 'N/A'
          change.permitNumber = item.permitNumber ?? 'N/A'
        }

        historyResults.push(change)
      }
    }
  }

  return _.orderBy(historyResults, ['updatedAt'], ['desc'])
}
