import { createContext, FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { registerAccessTokenMiddleware } from '../api'
import { AUTH_PROPERTIES } from '../constants'
import { jwtDecode, JwtPayload } from 'jwt-decode'
import { AuthenticationContextValues, AuthenticationContextValuesImpl } from './AuthenticationContextValues.ts'
import { bool } from '../utils/utils.ts'
import { mockAuthContextValues } from './authMocking.ts'
import { Page } from '../components/Page'
import { Stack, Typography } from '@mui/material'

const enableAuthMocking = bool(import.meta.env.VITE_ENABLE_AUTH_MOCKING)

const initialContextValues: AuthenticationContextValues = new AuthenticationContextValuesImpl(undefined, [])

interface JwtScope {
  scope?: string
}

export const AuthenticationContext = createContext<AuthenticationContextValues>(enableAuthMocking ? mockAuthContextValues : initialContextValues)
export const useAuthenticationContext = () => useContext(AuthenticationContext)

const AuthenticationContextProviderHelper: FC<PropsWithChildren> = ({ children }) => {
  const { isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0()
  const [accessTokenMiddlewareInitialized, setAccessTokenMiddlewareInitialized] = useState(false)
  const [authenticationContextValues, setAuthenticationContextValues] = useState<AuthenticationContextValues>(
    enableAuthMocking ? mockAuthContextValues : initialContextValues
  )
  const currentApiContextValues = useRef<AuthenticationContextValues>()

  useEffect(() => {
    if (isAuthenticated && !accessTokenMiddlewareInitialized && !enableAuthMocking) {
      const getAccessToken = async (usePopup?: boolean) => {
        try {
          let token: string | undefined
          const params = {
            authorizationParams: { audience: AUTH_PROPERTIES.MUSEUM_AUDIENCE }
          }
          if (usePopup) {
            token = await getAccessTokenWithPopup(params)
          } else {
            token = await getAccessTokenSilently(params)
          }

          if (token && token !== currentApiContextValues.current?.accessToken) {
            const decodedToken: JwtPayload & JwtScope = jwtDecode(token)

            currentApiContextValues.current = new AuthenticationContextValuesImpl(token, decodedToken?.scope?.split(' ') ?? [])
            setAuthenticationContextValues(currentApiContextValues.current)
          }

          if (token) {
            return token
          } else {
            throw new Error('No access token acquired')
          }
        } catch (e) {
          const error = e as { error: string }

          // While running in local, Auth0 may require consent via the popup. This code detects that case and retries the call with the popup.
          if (import.meta.env.DEV && error.error === 'consent_required' && !usePopup) {
            return await getAccessToken(true)
          }
          throw e
        }
      }

      // This is how the museum client gets its access tokens injected into each request
      registerAccessTokenMiddleware(() => getAccessToken())

      // Get the initial access token and set initialized to true
      getAccessToken().then(() => setAccessTokenMiddlewareInitialized(true))
    }
  }, [isAuthenticated, getAccessTokenSilently, getAccessTokenWithPopup, authenticationContextValues, accessTokenMiddlewareInitialized])

  if ((isAuthenticated && accessTokenMiddlewareInitialized) || enableAuthMocking) {
    return <AuthenticationContext.Provider value={authenticationContextValues}>{children}</AuthenticationContext.Provider>
  } else {
    return (
      <Page>
        <Stack alignItems={'center'} justifyContent={'center'} useFlexGap>
          <Typography>Initiating Login...</Typography>
        </Stack>
      </Page>
    )
  }
}

export const AuthenticationContextProvider = enableAuthMocking
  ? AuthenticationContextProviderHelper
  : withAuthenticationRequired(AuthenticationContextProviderHelper)
