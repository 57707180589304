import { FC, ReactNode } from 'react'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { PaperProps } from '@mui/material/Paper/Paper'

type AppCardProps = {
  name: string
  description: string
  icon: ReactNode
  color: string
  path: string
} & PaperProps

export const AppCard: FC<AppCardProps> = ({ name, description, icon, color, path, ...paperProps }) => {
  const navigate = useNavigate()
  return (
    <Paper
      onClick={() => navigate(path)}
      sx={{ overflow: 'hidden', borderRadius: 'var(--radius-l)', width: '18rem', cursor: 'pointer', height: '100%' }}
      {...paperProps}
    >
      <Box sx={{ backgroundColor: color, p: 'var(--spacing-m)' }}>{icon}</Box>
      <Stack sx={{ p: 'var(--spacing-m)', backgroundColor: 'var(bg-surface-low)' }} spacing={'1rem'}>
        <Typography sx={{ color: 'var(--text-primary)', fontSize: '1.75rem', lineHeight: '2rem', fontWeight: '600' }} component={'h2'}>
          {name}
        </Typography>
        <Typography sx={{ color: 'var(--text-secondary)' }}>{description}</Typography>
      </Stack>
    </Paper>
  )
}
