// my new favorite way to do enums in TypeScript https://www.youtube.com/watch?v=jjMbPt_H3RQ
import { AgencyPermitRequirementValues } from '../api'

export enum AgencyPermitRequirements {
  Required = 'Required',
  Recommended = 'Recommended',
  RequiredAfterFalseAlarmLimit = 'RequiredAfterFalseAlarmLimit',
  NotRequired = 'NotRequired',
  Unknown = 'Unknown'
}

export const AgencyPermitRequirementDisplayNames: Record<AgencyPermitRequirementValues, string> = {
  [AgencyPermitRequirements.Required]: 'Required',
  [AgencyPermitRequirements.Recommended]: 'Recommended',
  [AgencyPermitRequirements.RequiredAfterFalseAlarmLimit]: 'Required After False Alarm Limit',
  [AgencyPermitRequirements.NotRequired]: 'Not Required',
  [AgencyPermitRequirements.Unknown]: 'Unknown'
}
